import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import ModalContainer, {
  ModalBody,
  ModalHeader,
  ModalFooter
} from "./ModalContainer";

import { bindActionCreators } from "redux";
import { addNewCampaign } from "../../actions/NAID";

class AddCampaignModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      valid: false,
      message: "Requires Campaign Name"
    };
  }

  static defaultProps = {
    showOrganizationSelect: PropTypes.array.isRequired,
    organizationArray: PropTypes.array,
    organizationid: PropTypes.array
  }

  checkValid = () => {
    if (this.campaignname.value === "") {
      this.setState({
        valid: false,
        message: "Requires Campaign Name"
      });
      return;
    }
    
    if (this.props.showOrganizationSelect && this.selectedOrganization && this.selectedOrganization.value === "") {
      this.setState({
        valid: false,
        message: "Requires Organization Selection"
      });
      return;
    }

    this.setState({
      valid: true,
      message: ""
    });
  };

  addNewCampaign = () => {
    const organizationid =
      this.props.showOrganizationSelect
        ? this.selectedOrganization.value
        : this.props.organizationArray[0].organizationid;
    const campaignname = this.campaignname.value;

    this.props.addNewCampaign(
      campaignname,
      Number(organizationid)
    );
  };

  render() {  
    const { organizationArray, organizationid, showOrganizationSelect } = this.props;

    return (
      <ModalContainer
        name={
          <>
            <FontAwesomeIcon icon={faPlus} /> New Campaign
          </>
        }
        customButtonClass="button has-text-info"
      >
        {modalState => (
          <React.Fragment>
            <ModalHeader>Add Campaign</ModalHeader>
            <ModalBody>
              {/* {this.props.vitals.permissionlevel == 0 && ( */}
              {showOrganizationSelect && (
                <div>
                  <label className="label">Select Organization</label>
                  <div className="control">
                    <div className="select">
                      <select
                        ref={input => (this.selectedOrganization = input)}
                        defaultValue={organizationid}
                      >
                        {organizationArray.map((item, ind) => (
                          <option key={ind} value={item.organizationid}>
                            {item.organizationname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              <br />

              <label className="label">Campaign Name</label>
              <p className="control">
                <input
                  className="input"
                  name="copyScript"
                  type="campaignname"
                  ref={campaignname => {
                    this.campaignname = campaignname;
                  }}
                  onChange={this.checkValid}
                />
                {this.state.message && (
                  <p className="help is-danger">{this.state.message}</p>
                )}
              </p>

              <p>
                The campaign name will have its ID appended to it. E.G.
                "example_campaign (ID)"
              </p>
            </ModalBody>
            <ModalFooter {...modalState}>
              <button
                className="button is-info"
                onClick={() => {
                  this.addNewCampaign();
                  modalState.toggleOpen();
                }}
                disabled={!this.state.valid}
              >
                Add Campaign
              </button>
            </ModalFooter>
          </React.Fragment>
        )}
      </ModalContainer>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addNewCampaign
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddCampaignModal);
